import { Accordion, AccordionSummary, AccordionDetails, Box } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CoverageTypo from "./coverageTypo";
import CoverageExplaination from "./coverageExplaination_New";

// const accordianStyling = {
//   accordianContainer: { backgroundColor: ' rgba(74, 158, 66, 0.05)', boxShadow: 'none', padding: '0px 8px' },
//   accordianDetails: {
//     marginTop: '0px',
//   },
// };

const accordianStyling = {
  accordianContainer: {
    backgroundColor: "white",
    padding: "0px 8px",
    boxShadow: "2px 2px 10px rgba(0, 0, 0, 0.08), -2px -2px 4px rgba(0, 0, 0, 0.08)"
  },
  accordianDetails: {
    marginTop: "0px"
  }
};

const CoverageAccordion = () => (
  <Box className="uncoverage-accordian">
    <Accordion
      // defaultExpanded
      sx={accordianStyling.accordianContainer}
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
        <CoverageTypo />
      </AccordionSummary>
      <AccordionDetails sx={accordianStyling.accordianDetails}>
        <CoverageExplaination />
      </AccordionDetails>
    </Accordion>
  </Box>
);

export default CoverageAccordion;
