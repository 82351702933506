/* eslint-disable max-len */
import React from "react";
import { Box, Container, Stack, Typography } from "@mui/material";
import { useSelector } from "react-redux";

const KeyCoverage = () => {
  const insuranceProductId = window.localStorage.getItem("insuranceProductId");
  const { productDetailsInfo } = useSelector((state) => state.InsuranceReducer);
  const { metadata } = productDetailsInfo?.key_coverage_and_benifits || {};

  const productData = {
    ZBRQ13: [
      {
        text: "Loss of Job",
        image: require("../../assets/images/insuranceKeyCoverage/ZBRQ13_keyCoverage_1.png")
      },
      {
        text: "Family Related Incidents",
        image: require("../../assets/images/insuranceKeyCoverage/ZBRQ13_keyCoverage_2.png")
      },
      {
        text: "Cyber Frauds",
        image: require("../../assets/images/insuranceKeyCoverage/ZBRQ13_keyCoverage_3.png")
      }
    ],
    KECU4F: [
      {
        text: "Fraud Protection",
        image: require("../../assets/images/insuranceKeyCoverage/KECU4F_keyCoverage_1.png")
      },
      {
        text: "mWallet+UPI Protect",
        image: require("../../assets/images/insuranceKeyCoverage/KECU4F_keyCoverage_2.png")
      },
      {
        text: "12 Months Sony LIV",
        image: require("../../assets/images/insuranceKeyCoverage/KECU4F_keyCoverage_3.png")
      }
    ],
    RSCIFH: [
      {
        text: "Critical Illness",
        image: require("../../assets/images/insuranceKeyCoverage/RSCIFH_keyCoverage_1.png")
      },
      {
        text: "Outstanding Loan Protect",
        image: require("../../assets/images/insuranceKeyCoverage/RSCIFH_keyCoverage_2.png")
      },
      {
        text: "Kidney Failure",
        image: require("../../assets/images/insuranceKeyCoverage/RSCIFH_keyCoverage_3.png")
      }
    ],
    F56D66: [
      {
        text: "Outstanding Loan Protect",
        image: require("../../assets/images/insuranceKeyCoverage/F56D66_keyCoverage_1.png")
      },
      {
        text: "mWallet+UPI Protect",
        image: require("../../assets/images/insuranceKeyCoverage/F56D66_keyCoverage_2.png")
      },
      {
        text: "Emergency Travel Assistance",
        image: require("../../assets/images/insuranceKeyCoverage/F56D66_keyCoverage_3.png")
      }
    ]
  };

  const hardCodedData = productData?.[insuranceProductId];

  if (hardCodedData === null || hardCodedData === undefined) {
    return (
      <>
        <br />
        <br />
      </>
    );
  }

  return (
    <Container style={{ padding: "0px" }} className="keycoverage-details-container">
      <Typography fontWeight={600}>{metadata?.title || "Key Coverage & Benefits"}</Typography>
      <Box mt={2}>
        <Stack direction="row" spacing={2} alignItems="center">
          {hardCodedData.map(({ text, image }, index) => (
            <Box key={index} height="110px" width="80px">
              <img
                src={image}
                alt={text}
                style={{
                  width: "36px",
                  height: "auto",
                  display: "block",
                  marginLeft: "auto",
                  marginRight: "auto"
                }}
              />
              <Typography mt={1} fontWeight={500} fontSize="10px" lineHeight="12px" color="#000" textAlign="center">
                {text}
              </Typography>
            </Box>
          ))}
        </Stack>
      </Box>
    </Container>
  );
};

export default KeyCoverage;
