import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Box, Button, createTheme, useMediaQuery, Drawer, Backdrop } from "@mui/material";
import { buttonStyle } from "./Components/buttonStyle";
import BasicInfoOtp from "./Components/basicInfoOtp";
import { toggleBasicInfo } from "./Redux/actions";
import BasicInfoPayment from "./Components/basicInfoPayment";
import { logFirebaseEventFromWeb } from "../../utils/helper";
import { sendEventToClevertap } from "../../actions/clevertapActions";
import { getAuthToken } from "../Insurance/insuranceData";
import { productTitleMap } from "./Components/utils";

const BuyNowCTA = ({ payNow }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const theme = createTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up("sm"));
  const { showBasicInfo, productDetailsInfo } = useSelector((state) => state.InsuranceReducer);
  const { data } = productDetailsInfo?.product_details || {};
  const { title, sub_title } = data;

  const handleClick = () => {
    logFirebaseEventFromWeb("insurance_action_buy_now_click");
    const { authToken = "" } = getAuthToken();
    dispatch(
      sendEventToClevertap({
        auth_token: authToken,
        eventData: {
          event_name: "Pageviewed",
          event_property: { Product: "Insurance", Category: title, subCategory: sub_title, CTA: "pay now" }
        }
      })
    );
    if (!showBasicInfo) {
      dispatch(toggleBasicInfo({ show: true }));
    }
    navigate("/insurance/request-otp");
  };

  useEffect(() => {
    if (!showBasicInfo && !payNow) {
      document.body.style.overflow = "";
    }
  }, [showBasicInfo, payNow]);

  const handleClose = () => {
    const productId = window.localStorage.getItem("insuranceProductId");
    const titleName = productTitleMap[productId]?.toLowerCase() || "insurance";
    const queryParams = new URLSearchParams({ title: titleName });
    navigate(`/insurance/${productId}?${queryParams.toString()}`);
    dispatch(toggleBasicInfo({ show: false }));
  };

  return (
    <Box>
      {!isLargeScreen && (
        <Box>
          {!showBasicInfo && !payNow && (
            <Box
              p={2}
              borderTop="1px solid #E3E3E3"
              position="fixed"
              bottom="0"
              left="0"
              right="0"
              bgcolor="#fff"
              zIndex={theme.zIndex.drawer + 1}
            >
              <Button variant="contained" size="large" sx={buttonStyle} onClick={handleClick} fullWidth>
                Buy now
              </Button>
            </Box>
          )}
          {showBasicInfo && !payNow && (
            <Box>
              <Backdrop
                onClick={handleClose}
                open={showBasicInfo}
                sx={{ zIndex: theme.zIndex.drawer - 1, backgroundColor: "rgba(0, 0, 0, 0.5)" }}
              />
              <Drawer
                onClose={handleClose}
                open={showBasicInfo}
                anchor="bottom"
                PaperProps={{ sx: { borderTopLeftRadius: 16, borderTopRightRadius: 16, padding: 2 } }}
              >
                <BasicInfoOtp terms={data.terms_and_conditions} />
              </Drawer>
            </Box>
          )}
          {payNow && (
            <Box>
              <Backdrop
                onClick={handleClose}
                open={payNow}
                sx={{ zIndex: theme.zIndex.drawer - 1, backgroundColor: "rgba(0, 0, 0, 0.5)" }}
              />
              <Drawer
                onClose={handleClose}
                open={payNow}
                anchor="bottom"
                PaperProps={{ sx: { borderTopLeftRadius: 16, borderTopRightRadius: 16, padding: 2 } }}
              >
                <BasicInfoPayment />
              </Drawer>
            </Box>
          )}
        </Box>
      )}
    </Box>
  );
};

export default BuyNowCTA;
