import React from "react";
import { Box } from "@mui/material";
import UnCoverageTypo from "./Components/uncoverageTypo";
import UnCoverageExplaination from "./Components/uncoverageExplaination";
import UnCoverageAccordionNew from "./Components/accordianUnCoverage_New";

const UnCoverageDetails = () => (
  <>
    <Box className="uncoverage-details-container">
      <UnCoverageTypo />
      <UnCoverageExplaination />
    </Box>
    <UnCoverageAccordionNew />
  </>
);

export default UnCoverageDetails;
