import { Typography } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";

const PageTitle = () => {
  const { productDetailsInfo } = useSelector((state) => state.InsuranceReducer);
  const { metadata } = productDetailsInfo?.product_details || {};
  const { title } = metadata;

  return (
    <Typography
      className="pageTitle"
      variant="h1"
      component="h1"
      sx={{
        fontSize: "18px",
        fontWeight: "600",
        lineHeight: "30px",
        color: "#1D1617",
        marginBottom: "16px"
      }}
      onClick={() => {
        window.location.href = "/insurance";
      }}
    >
      <KeyboardArrowLeftIcon sx={{ color: "#1D1617", marginRight: "6px" }} />
      {title}
    </Typography>
  );
};

export default PageTitle;
