import React from "react";
import { useSelector } from "react-redux";
import { Box, Typography } from "@mui/material";
import CircleIcon from "@mui/icons-material/Circle";

const DetailExplaination = () => {
  const { productDetailsInfo } = useSelector((state) => state.InsuranceReducer);
  const { data } = productDetailsInfo.key_coverage_and_benifits;

  return (
    <Box className="uncoverage-details uncoverage-accordian-details" mt={2}>
      {data?.text_pair?.map(({ text, subtext }, index) => (
        <Box className="uncoverage-points" key={index}>
          <CircleIcon
            sx={{
              color: "#000066",
              fontSize: "12px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "4px"
            }}
            alt="right-tick"
          />
          <Box>
            <Typography>{text}</Typography>
            <Typography>{subtext}</Typography>
          </Box>
        </Box>
      ))}
    </Box>
  );
};

export default DetailExplaination;
