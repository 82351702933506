import React from "react";
import { Box, Container, Typography } from "@mui/material";
import { useSelector } from "react-redux";

const KeyCoverageDetails = () => {
  const { productDetailsInfo } = useSelector((state) => state.InsuranceReducer);
  const { data, metadata } = productDetailsInfo?.key_coverage_and_benifits || {};

  return (
    <Container style={{ padding: "0px" }} className="keycoverage-details-container">
      <Typography fontWeight={600}>{metadata?.title}</Typography>
      <Box className="keycoverage-details" mt={1}>
        <ul>
          {data?.text_pair?.map(({ text, subtext }) => (
            <li key={text}>
              <Typography>{text}</Typography>
              <Typography>{subtext}</Typography>
            </li>
          ))}
        </ul>
      </Box>
    </Container>
  );
};

export default KeyCoverageDetails;
