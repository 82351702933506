import { Accordion, AccordionSummary, AccordionDetails, Box } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CoverageTypo from "./coverageTypo";
import CoverageExplaination from "./coverageExplaination";

const accordianStyling = {
  accordianContainer: { backgroundColor: " rgba(74, 158, 66, 0.05)", boxShadow: "none", padding: "0px 8px" },
  accordianDetails: {
    marginTop: "0px"
  }
};

const CoverageAccordion = () => (
  <Box className="coverage-accordian">
    <Accordion defaultExpanded sx={accordianStyling.accordianContainer}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
        <CoverageTypo />
      </AccordionSummary>
      <AccordionDetails sx={accordianStyling.accordianDetails}>
        <CoverageExplaination />
      </AccordionDetails>
    </Accordion>
  </Box>
);

export default CoverageAccordion;
