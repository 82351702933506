import { Helmet } from "react-helmet-async";

export default function HomeScripts() {
  return (
    <Helmet>
      "
      <script type="application/ld+json">
        {`
          "@context": "https://schema.org",
          "@type": "Organization",
          "name": "Stashfin",
          "url": "https://www.stashfin.com/",
          "logo": "https://www.stashfin.com/assets/images/stashfin-logo.svg",
          "contactPoint": {
            "@type": "ContactPoint",
            "telephone": "+91-XXXXXXXXXX",
            "contactType": "customer service",
            "contactOption": "HearingImpairedSupported",
            "areaServed": ["IN"],
            "availableLanguage": ["en", "hi"]
          },
          "sameAs": [
            "https://www.facebook.com/StashfinApp",
            "https://www.linkedin.com/company/stashfin/",
            "https://twitter.com/Stashfin",
            "https://www.instagram.com/stashfin_official/"
          ]
        `}
      </script>
      " "
      <script type="application/ld+json">
        {`
          "@context": "https://schema.org/",
          "@type": "WebSite",
          "name": "Stashfin",
          "url": "https://www.stashfin.com/",
          "potentialAction": {
            "@type": "SearchAction",
            "target": "https://www.stashfin.com/search?q={search_term_string}",
            "query-input": "required name=search_term_string"
          }
        `}
      </script>
      " "
      <script type="application/ld+json">
        {`
          "@context": "https://schema.org/", 
          "@type": "BreadcrumbList", 
          "itemListElement": [{
            "@type": "ListItem", 
            "position": 1, 
            "name": "Home",
            "item": "https://www.stashfin.com/"   
          }]
        `}
      </script>
      "
    </Helmet>
  );
}
