/* eslint-disable complexity */
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Box, Button, Container, Typography, createTheme, useMediaQuery } from "@mui/material";
import { buttonStyle } from "./Components/buttonStyle";
import BasicInfoOtp from "./Components/basicInfoOtp";
import { toggleBasicInfo } from "./Redux/actions";
import BasicInfoPayment from "./Components/basicInfoPayment";
import { logFirebaseEventFromWeb } from "../../utils/helper";
import { sendEventToClevertap } from "../../actions/clevertapActions";
import { getAuthToken } from "../Insurance/insuranceData";

const ProductDetails = ({ payNow }) => {
  const navigate = useNavigate();
  const theme = createTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up("sm"));
  const dispatch = useDispatch();
  const { productDetailsInfo, showBasicInfo } = useSelector((state) => state.InsuranceReducer);
  const { metadata, data } = productDetailsInfo?.product_details || {};
  const { title, sub_title, type, coverage_amount, tenure, premium, product_covered } = data;

  useEffect(() => {
    const { authToken = "" } = getAuthToken();
    dispatch(
      sendEventToClevertap({
        auth_token: authToken,
        eventData: {
          event_name: "Pageviewed",
          event_property: {
            pagename: "insurance detail page",
            Product: "Insurance",
            Action: "Page view",
            Category: title,
            subCategory: sub_title
          }
        }
      })
    );
  }, []);

  const handleClick = () => {
    logFirebaseEventFromWeb("insurance_action_buy_now_click");
    const { authToken = "" } = getAuthToken();
    dispatch(
      sendEventToClevertap({
        auth_token: authToken,
        eventData: {
          event_name: "Pageviewed",
          event_property: { Product: "Insurance", Category: title, subCategory: sub_title, CTA: "pay now" }
        }
      })
    );
    if (!showBasicInfo) {
      dispatch(toggleBasicInfo({ show: true }));
    }
    navigate("/insurance/request-otp");
  };
  return (
    <Box maxWidth="lg" className="product-container">
      <Box className="product-child-container">
        <Box className="product-heading">
          <Typography variant="caption">{metadata.title}</Typography>
        </Box>
        <Container className="product-details" maxWidth={false}>
          <Box>
            <Box>
              <img src={data.logo} alt="stashfin insurance logo" />
            </Box>
            <Box>
              <Typography>{title}</Typography>
              <Typography>{sub_title}</Typography>
              <Typography>{type}</Typography>
            </Box>
          </Box>
          <Box>
            <Box>
              <Typography>{coverage_amount === 0 ? "Product covered" : "Coverage upto"}</Typography>
              {coverage_amount === 0 ? (
                <Typography fontWeight={600}> {product_covered}</Typography>
              ) : (
                <Typography fontWeight={600}>₹ {coverage_amount}</Typography>
              )}
            </Box>
            <Box>
              <Typography>Tenure</Typography>
              <Typography fontWeight={600}>{tenure} </Typography>
            </Box>
            <Box>
              {" "}
              <Typography>Premium</Typography>
              <Typography fontWeight={600}>₹ {premium}</Typography>
            </Box>
          </Box>
          <Box>
            {!isLargeScreen && showBasicInfo && !payNow && <BasicInfoOtp terms={data.terms_and_conditions} />}
            {!showBasicInfo && !isLargeScreen && !payNow && (
              <Button variant="contained" size="large" sx={buttonStyle} onClick={handleClick} fullWidth>
                Buy now
              </Button>
            )}
            {isLargeScreen && (
              <Button variant="contained" size="large" sx={buttonStyle} onClick={handleClick} fullWidth>
                Buy now
              </Button>
            )}

            {!isLargeScreen && payNow && <BasicInfoPayment />}
          </Box>
        </Container>
      </Box>
    </Box>
  );
};
export default ProductDetails;
