import React from "react";
import { useSelector } from "react-redux";
import { Typography } from "@mui/material";

const DetailTypo = () => {
  const { productDetailsInfo } = useSelector((state) => state.InsuranceReducer);
  const { metadata } = productDetailsInfo?.details || {};

  return (
    <Typography variant="body2" fontWeight={600}>
      {metadata?.title || "Details"}
    </Typography>
  );
};

export default DetailTypo;
