import React from "react";
import { Box } from "@mui/material";
import DetailExplaination from "./Components/detailExplaination";
import DetailAccordion from "./Components/accordianDetail";
import DetailTypo from "./Components/detailTypo";

const Details = () => (
  <>
    <Box className="uncoverage-details-container">
      <DetailTypo />
      <DetailExplaination />
    </Box>
    <DetailAccordion />
  </>
);

export default Details;
