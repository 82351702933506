import React from "react";
import { useSelector } from "react-redux";
import { Box, Typography } from "@mui/material";
import RightTick from "../../../assets/images/insuranceJourneyImages/right-tick.png";

const CoverageExplaination = () => {
  const { productDetailsInfo } = useSelector((state) => state.InsuranceReducer);

  const { data } = productDetailsInfo.whats_covered;
  return (
    <Box className="uncoverage-details uncoverage-accordian-details" mt={2}>
      {data.key_points?.map((el) => (
        <Box className="uncoverage-points" key={el}>
          <img src={RightTick} alt="right-tick" />
          <Typography>{el}</Typography>
        </Box>
      ))}
    </Box>
  );
};

export default CoverageExplaination;
