import { Helmet } from "react-helmet-async";
import { Container } from "@mui/material";
import KeyCoverageDetails from "./keyCoverageDetails";
import ProductDetails from "./productDetails";
import ProductDetailsNew from "./productDetails_New";
import CoverageDetails from "./coverageDetails";
import CoverageDetailsNew from "./coverageDetails_New";
import UnCoverageDetails from "./uncoverageDetails";
import LinksAndDocuments from "./linksAndDocument";
import Details from "./details";
import KeyCoverage from "./keyCoverage";
import PageTitle from "./pageTitle";
import BuyNowCTA from "./buyNowCTA";

const InsuranceDetails = ({ payNow }) => {
  const insuranceProductId = window.localStorage.getItem("insuranceProductId");
  const isNewDesign = ["ZBRQ13", "KECU4F", "RSCIFH", "F56D66"].includes(insuranceProductId);

  if (isNewDesign) {
    require("./insurance_New.scss");
  } else {
    require("./insurance.scss");
  }

  if (isNewDesign) {
    return (
      <Container className="main-container-new" sx={{ paddingBottom: "50px" }}>
        <Helmet>
          <title>Insurance</title>
        </Helmet>
        <PageTitle />
        <ProductDetailsNew />
        <KeyCoverage />
        <Details />
        <CoverageDetailsNew />
        <UnCoverageDetails />
        <LinksAndDocuments />
        <BuyNowCTA payNow={payNow} />
      </Container>
    );
  }

  return (
    <Container className="main-container">
      <Helmet>
        <title>Insurance</title>
      </Helmet>
      <ProductDetails payNow={payNow} />
      <KeyCoverageDetails />
      <CoverageDetails />
      <UnCoverageDetails />
      <LinksAndDocuments />
    </Container>
  );
};

export default InsuranceDetails;
