import React from "react";
import { Box } from "@mui/material";
import CoverageTypo from "./Components/coverageTypo";
import CoverageExplaination from "./Components/coverageExplaination_New";
import CoverageAccordion from "./Components/accordianCoverage_New";

const CoverageDetails = () => (
  <>
    <Box className="uncoverage-details-container">
      <CoverageTypo />
      <CoverageExplaination />
    </Box>
    <CoverageAccordion />
  </>
);

export default CoverageDetails;
